import { Container, Image, Row, Col } from "react-bootstrap"
import Wanderlust from "./img/Wanderlust400.jpg";
import AtTheVillage from "./img/AtTheVillage400.jpg";
import Discover from "./img/Discover400.jpg";
// import Dream from "./img/Dream400.jpg";
// import Enchanted from "./img/Enchanted400.jpg";
// import Enter from "./img/Enter400.jpg";
// import Fairytale from "./img/Fairytale400.jpg";
// import India from "./img/India400.jpg";
// import Opportunity from "./img/Opportunity400.jpg";
// import Portal from "./img/Portal400.jpg";
// import Red from "./img/Red400.jpg";
// import SomewhereInItaly from "./img/SomewhereInItaly400.jpg";
// import TealDoor from "./img/TealDoor400.jpg";
// import ThePath from "./img/ThePath400.jpg";
// import Unknown from "./img/unknown400.jpg";



import DoorsIntro from "./DoorsIntro";
import { Link } from "react-router-dom";




export default function DoorsShow() {
  document.title = "Melissa Gerhold - Doors";




  
  return (
    <>
<Container fluid className="bg-dark text-white p-3">
        <Link
          to="/"
          className="btn btn-primary text-bg-dark"
          style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
        >
          Home
        </Link>
      </Container>

    <DoorsIntro/>

    <Container>
<Row>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Wanderlust} alt="Wanderlust" />
  <p className="text-center small mt-2">Wanderlust</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={AtTheVillage} alt="At The Village" />
  <p className="text-center small mt-2">At The Village</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Discover} alt="Discover" />
  <p className="text-center small mt-2">Discover</p>
</Col>


<p className="h4">More will be revealed during show, Sept 2024. Meet the artist at Banter Brewing in Springfield Missouri, Sept 6. </p>

{/* <Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Dream} alt="Dream" />
  <p className="text-center small mt-2">Dream</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Enchanted} alt="Enchanted" />
  <p className="text-center small mt-2">Enchanted</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Enter} alt="Enter" />
  <p className="text-center small mt-2">Enter</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Fairytale} alt="Fairytale" />
  <p className="text-center small mt-2">Fairytale</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={India} alt="India" />
  <p className="text-center small mt-2">India</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Opportunity} alt="Opportunity" />
  <p className="text-center small mt-2">Opportunity</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Portal} alt="Portal" />
  <p className="text-center small mt-2">Portal</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Red} alt="Red" />
  <p className="text-center small mt-2">Red</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={SomewhereInItaly} alt="Somewhere in Italy" />
  <p className="text-center small mt-2">Somewhere in Italy</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={TealDoor} alt="Teal Door" />
  <p className="text-center small mt-2">Teal Door</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={ThePath} alt="The Path" />
  <p className="text-center small mt-2">The Path</p>
</Col>
<Col lg={4} md={4} xs={12} className="p-3 d-flex flex-column align-items-center">
  <Image className="matte border-3 m-2" fluid src={Unknown} alt="Unknown" />
  <p className="text-center small mt-2">Unknown</p>
</Col> */}


</Row>



    </Container>


    <Container fluid className="bg-dark text-white p-3">
        <Link
          to="/"
          className="btn btn-primary text-bg-dark"
          style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
        >
          Home
        </Link>
      </Container>


{/* 
    <Container className="p-5">
        <Row>
<Col lg={4} md={12} className=" text-center">
<Image className="matte border-3" fluid src={Wanderlust} alt="Wanderlust" />

</Col>
<Col lg={8} md={12} className="p-5">

<p className="fw-bold lh-1 h3">Introducing:</p>

<p className="display-1 lh-1 h1 fw-bold text-uppercase" style={{fontSize:"10vw"}}>Doors</p>
<p className="h5">Doors represent new opportunities, exciting possibilities, and untold adventures. Come on in and enjoy an adventure in my new series called DOORS. </p>

<div className="text-center d-flex flex-column align-items-center">
<p className="mt-5 blockquote text-center col-md-7 col-sm-12">"The doors we open and close each day decide the lives we live."
    </p>
    <p className="blockquote0footer text-center">—Flora Whittemore</p>
    
    </div>

</Col>
        </Row>

    </Container>
     */}
    
    
    
    
    </>
  )
}
