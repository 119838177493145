import { Container, Image, Row, Col } from "react-bootstrap"
import Wanderlust from "./img/Wanderlust400.jpg"
export default function DoorsIntro() {
  return (
    <>

    <Container className="p-5">
        <Row>
<Col lg={4} md={12} className=" text-center">
<Image className="matte border-3" fluid src={Wanderlust} alt="Wanderlust" />

</Col>
<Col lg={8} md={12} className="p-5">

<p className="fw-bold lh-1 h3">Introducing:</p>

<p className="display-1 lh-1 h1 fw-bold text-uppercase" style={{fontSize:"10vw"}}>Doors</p>
<p className="h5">Doors represent new opportunities, exciting possibilities, and untold adventures. Come on in and enjoy an adventure in my new series called DOORS. </p>

<div className="text-center d-flex flex-column align-items-center">
<p className="mt-5 blockquote text-center col-md-7 col-sm-12">"The doors we open and close each day decide the lives we live."
    </p>
    <p className="blockquote0footer text-center">—Flora Whittemore</p>
    
    </div>

</Col>
        </Row>

    </Container>
    
    
    
    
    
    </>
  )
}
